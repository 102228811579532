import React from 'react';
import './App.css';
import edictums from './entries';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import numeral from 'numeral';


import {
    HashRouter,
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";




function App() {
    const formatAmount = amount => {
        return numeral(amount).format('0,0.00');
    };
    function priceFormatter(cell, row) {
        return `${formatAmount(cell)} ${row.amountCurrency}`;
    }
    function LinkFormatter(value, row, index) {
        return <Link to={`/${value}`}>{value}</Link>;
    }
  return (
    <div className="App">


      <header className="App-header">

          <HashRouter basename='/'>
              <div>


                  {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                  <Switch>
                      <Route path="/users">
                          <div>
                              Users
                          </div>
                      </Route>
                      <Route path="/:id" children={<Child />}>

                      </Route>

                      <Route path="/">
                          <div>
                              <BootstrapTable  data={ edictums }>
                                  <TableHeaderColumn dataField='number' isKey={ true } dataFormat={LinkFormatter} ># Edicto</TableHeaderColumn>
                                  <TableHeaderColumn dataField='newsletterTitle' dataSort={ true } tdStyle={ { whiteSpace: 'normal' } } >Boletín</TableHeaderColumn>
                                  <TableHeaderColumn dataField='type' dataSort={ true }>Tipo</TableHeaderColumn>
                                  <TableHeaderColumn dataField='province' dataSort={ true }>Provincia</TableHeaderColumn>
                                  <TableHeaderColumn dataField='canton' dataSort={ true }>Cantón</TableHeaderColumn>
                                  <TableHeaderColumn dataField='district' dataSort={ true }>District</TableHeaderColumn>
                                  <TableHeaderColumn dataField='amount' dataSort={ true } dataFormat={ priceFormatter }>Monto</TableHeaderColumn>
                                  <TableHeaderColumn dataField='firstBidDate' dataSort={ true } tdStyle={ { whiteSpace: 'normal' } } >Fecha Remate</TableHeaderColumn>
                              </BootstrapTable>
                          </div>
                      </Route>
                  </Switch>
              </div>
          </HashRouter>






      </header>
    </div>
  );
}
function Child() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams();
    const edictum = edictums.filter(edictum=> edictum.number===id);
    return (
        <div>
            <div>
                <a href={`/`}>Regresar a inicio</a>
            </div>
            <div>
                &nbsp;
            </div>

            <table>

                <tr>
                    <td>number</td>
                    <td>{edictum[0].number}</td>
                </tr>
                <tr>
                    <td>newsletterTitle</td>
                    <td>{edictum[0].newsletterTitle}</td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>{edictum[0].type}</td>
                </tr>
                <tr>
                    <td>province</td>
                    <td>{edictum[0].province}</td>
                </tr>
                <tr>
                    <td>canton</td>
                    <td>{edictum[0].canton}</td>
                </tr>
                <tr>
                    <td>amount</td>
                    <td>{ `${edictum[0].amountCurrency} ${numeral(edictum[0].amount).format('0,0.00')}`}</td>
                </tr>
                <tr>
                    <td>firstBidDate</td>
                    <td>{edictum[0].firstBidDate}</td>
                </tr>
                <tr>


                </tr>






            </table>

            <div>
                &nbsp;
            </div>
        <div>
            {edictum[0].scrapedText}
        </div>
        <div>
           {/* Fuente: <a href={`https://www.imprentanacional.go.cr/boletin/?date=20/05/2020`}>{edictum[0].newsletterTitle}</a>*/}
        </div>

            <div>
                &nbsp;
            </div>
            <div><strong>Desea recibir el catastro de esta propiedad en su correo electrónico?</strong></div>
            <div>El costo del catastro es 6800 colones. Enviar correo con el número {edictum[0].number} a <a href={'mailto:soporte.remates@gmail.com'} >soporte.remates@gmail.com</a> para más información.</div>

        </div>
    );
}

export default App;
